import React, { useState } from 'react'
import { Row, Col } from 'antd'
import {LoadingOutlined} from '@ant-design/icons'

const Batsmen = ({ score, viewport, fontSize }) => {

   // this code refers if match was complete it show next match data
    const progress = score.matchHeader?.state 
    var matchCompleted = false
    if (progress == "Complete") {
        matchCompleted = true
    } else {
        matchCompleted = false
    }
    
    // this total code refers reload icon when 
    if (score.miniscore === undefined) {
        return <Row justify={'center'} > 
                  <Col xs={viewport.xs} sm={viewport.sm - 1} lg={viewport.lg - 1}>
                       <Row>
                         {progress !== 'Preview' && <LoadingOutlined className='loadingIcon'/>}
                       </Row>
                   </Col>
                </Row>
    }

    //this is batman stricker
    const batmanName = score.miniscore.batsmanStriker.batName ? score.miniscore.batsmanStriker.batName : '-'
    const batmanRun = score.miniscore.batsmanStriker.batRuns
    const batmanBall = score.miniscore.batsmanStriker.batBalls
    const batmanFour = score.miniscore.batsmanStriker.batFours
    const batmanSix = score.miniscore.batsmanStriker.batSixes
    const batmanStrikeRate = score.miniscore.batsmanStriker.batStrikeRate

    //this is batman non striker
    const nonbatmanName = score.miniscore.batsmanNonStriker.batName
    const nonbatmanRun = score.miniscore.batsmanNonStriker.batRuns
    const nonbatmanBall = score.miniscore.batsmanNonStriker.batBalls
    const nonbatmanFour = score.miniscore.batsmanNonStriker.batFours
    const nonbatmanSix = score.miniscore.batsmanNonStriker.batSixes
    const nonbatmanStrikeRate = score.miniscore.batsmanNonStriker.batStrikeRate

    //this bowler 
    const bowlername = score.miniscore.bowlerStriker.bowlName
    const bowlerovers = score.miniscore.bowlerStriker.bowlOvs
    const bowlermaiden = score.miniscore.bowlerStriker.bowlMaidens
    const bowlerruns = score.miniscore.bowlerStriker.bowlRuns
    const bowlerWicket = score.miniscore.bowlerStriker.bowlWkts
    const bowlerEconomy = score.miniscore.bowlerStriker.bowlEcon

    //this is non stricker name
    const nonbowlername = score.miniscore.bowlerNonStriker.bowlName
    const nonbowlerovers = score.miniscore.bowlerNonStriker.bowlOvs
    const nonbowlermaiden = score.miniscore.bowlerNonStriker.bowlMaidens
    const nonbowlerruns = score.miniscore.bowlerNonStriker.bowlRuns
    const nonbowlerWicket = score.miniscore.bowlerNonStriker.bowlWkts
    const nonbowlerEconomy = score.miniscore.bowlerNonStriker.bowlEcon


    return (
        <div className='batsmen'>
            <Row justify={'space-around'}>
                <Col xs={viewport.xs} sm={viewport.sm - 1} lg={viewport.lg - 1} style={fontSize}>
                    <table className='batter_bowler_table'> 
                        <thead>
                            <tr className='heads'>
                                <th>Batting </th>
                                <th>R</th>
                                <th>B</th>
                                <th>4s</th>
                                <th>6s</th>
                                <th>SR</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td><b>{batmanName ? `${batmanName} *`: '-'}</b> </td>
                                <td>{batmanRun}</td>
                                <td>{batmanBall}</td>
                                <td>{batmanFour} </td>
                                <td>{batmanSix}</td>
                                <td>{batmanStrikeRate}</td>
                            </tr>
                            <tr>
                                <td><b>{nonbatmanName ? nonbatmanName : '-'}</b></td>
                                <td>{nonbatmanRun}</td>
                                <td>{nonbatmanBall}</td>
                                <td>{nonbatmanFour}</td>
                                <td>{nonbatmanSix}</td>
                                <td>{nonbatmanStrikeRate}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className='batter_bowler_table'>
                        <thead>
                            <tr className='heads'>
                                <th>Bowling</th>
                                <th>O</th>
                                <th>M</th>
                                <th>R</th>
                                <th>W</th>
                                <th>ECO</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><b>{bowlername ? `${bowlername} * `: '-'}</b> </td>
                                <td>{bowlerovers} </td>
                                <td>{bowlermaiden}</td>
                                <td>{bowlerruns}</td>
                                <td>{bowlerWicket}</td>
                                <td>{bowlerEconomy}</td>
                            </tr>
                            {bowlerovers ?
                                <tr>
                                    <td><b>{nonbowlername ? nonbowlername : '-'}</b></td>
                                    <td>{nonbowlerovers}</td>
                                    <td>{nonbowlermaiden}</td>
                                    <td>{nonbowlerruns}</td>
                                    <td>{nonbowlerWicket}</td>
                                    <td>{nonbowlerEconomy}</td>
                                </tr>
                                : null}

                        </tbody>

                    </table>
                </Col>
            </Row>
        </div>

    )
}

export default Batsmen