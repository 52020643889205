"use client"
import React from 'react';
import { CbWrapper } from 'configbee-react';
const ConfigbeeProvider = ({ children }) => {
    const envKey = process.env.REACT_APP_CONFIGBEE_KEY
    return (
        <div>
            <CbWrapper
                accountId="65f689338d4fccd15246774f"
                projectId="660122a7e2bb6172c392a845"
                environmentId={envKey}
            >
                {children}
            </CbWrapper>
        </div>
    )
}

export default ConfigbeeProvider