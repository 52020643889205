import './App.css';
import Batsmen from './Batsmen';
import ScoreBoard from './ScoreBoard';
import { useEffect, useState } from 'react';
import PoweredBy from './PoweredBy';
import { useCbJsons,useCbFlags } from 'configbee-react'
import BackgroundImage from './BackgroundImage';
import { db } from './firebase.js'
import { doc, collection, query, where, getDocs, orderBy,onSnapshot } from "firebase/firestore";
import PreShowSite from './PreShowSite.jsx';
import moment from 'moment'

const fetchFireStoreData = async (topicId,partnerName) => {
  const topic_ref = doc(collection(db, "topics"), topicId);
  const poll_state_open = doc(collection(db, "poll-states"), "OPEN");
  const q = query(collection(db, "polls"),
    where("topic", "==", topic_ref), 
    where("state", "==", poll_state_open),
    where("type", "==", "question"), // this line refers, only fetch (type = "question") 
    orderBy("created_at", "desc") // sorting
  );
    const querySnapshot = await getDocs(q);
    //  below codes for fetch latest poll question from firebase
    const document = querySnapshot.docs[0]
    if(!document){
      return
    }
    return formatPollDetails(document, partnerName);
}

// this function for getting progressed match and next match from configBee
const getCurrentAndNextMatchIds = (matchsData) => {
  if(!matchsData){
    return
  }
  const currentDate = new Date()
  const progressMatchs = matchsData.filter((match) => new Date(match.date) < currentDate) //filtered by current date
  const nextMatchs = matchsData.filter((match) => new Date(match.date) > currentDate)

  const progressMatch = progressMatchs[progressMatchs.length - 1]//this code for getting current match _id and display this data while next mathes starts
  const nextMatch = nextMatchs[0] //this code for getting next match id
  let currentId, nextId
  const nextMatchDate = nextMatch?.date; //compute next match date
  const reducedNextMatchDate = new Date(nextMatchDate);
  reducedNextMatchDate.setMinutes(reducedNextMatchDate.getMinutes() - 30) //reduce time 30 min from match start time .fo showing toss result 
  if (currentDate >= reducedNextMatchDate) { // this code for comparing current match and next match date & time for showing toss
    currentId = nextMatch?.cricbuzz_id
    nextId = undefined
  } else {
    currentId = progressMatch?.cricbuzz_id
    nextId = nextMatch?.cricbuzz_id
  }
  return [currentId, nextId]
}

function formatPollDetails(document, partnerName) {
  const poll_deeplink = `https://impact11.in/polls?id=${document.id}&utm_campaign=${partnerName ? partnerName : 'Impact11'}`;
  return {
    qrUrl: poll_deeplink,
    pollQuestionTitle: document.data().title,
    pollQuestionOption: document.data().question_options?.choices || [],
    deadlineTime: document.data().deadline
  };
}

function App() {

  const [time, setTime] = useState(new Date());
  const [scoreFetechedDate, setScoreFetechedDate] = useState()
  const [isFetchingScoreFetechedDate, setIsFetchingScoreFetechedDate] = useState(false)
  const [score, setScore] = useState('') //this score state have the all api responce data
  const { availableMatches } = useCbJsons() // configbee pakages
  const [currentMatchId, setCurrentMatchId] = useState()
  const [nextMatchId, setNextMatchId] = useState()

  const [pollDetails, setPollDetails] = useState()
  
  const [topicIDFb,setTopicIDFb] = useState('') //topic id from configBee
  const { enableLivePolls } = useCbFlags() 
  
  const [progress , setProgress] = useState('')
  const extraLargeViewport = window.innerHeight >= 900 ? 20 :  14
  const viewport = { xs: 18, sm: 16, lg: extraLargeViewport , xl: 24 } // viewport design
  const fontSize = { color: 'white' } // this code define styles
  const preview = false // this code for cancelling images preview 
  const centerContent = 'content_center'   // this code for classname "center_content" 
  const urlParams = new URLSearchParams(window.location.search) //this code for getting url params
  const partnerName = urlParams.get('partner')
  const confirmPartnerName =  partnerName ? partnerName : 'Impact11' 
  const orientation =  urlParams.get('orientation')
  let rotate = false;
  if (confirmPartnerName === 'adstandee' && orientation === 'portrait') {
     rotate = true      
  }    
  const getCurrentAndNextMatchData = () => {
    const matchsData = availableMatches?.matches
    if(!matchsData){
      return [undefined, undefined]
    }
    return [
      matchsData.filter((each)=>each.cricbuzz_id==currentMatchId)[0],
      matchsData.filter((each)=>nextMatchId&&each.cricbuzz_id==nextMatchId)[0],
    ]
  }

  // this code for fetching data from api 
  const refreshScoreAndProgress = async () => {
    if(isFetchingScoreFetechedDate){
      return
    }
    if (!currentMatchId) { return }
    const currentTimeStamp = Date.now() //getting current date and time
    const URL = `https://api.allorigins.win/raw?url=https://www.cricbuzz.com/api/cricket-match/commentary/${currentMatchId}?timestamp=${currentTimeStamp}`
    try {
      setIsFetchingScoreFetechedDate(true)
      const responce = await fetch(URL)
      if (!responce.ok) { throw new Error('error in url') }
      const data = await responce.json()
      setScore(data)
      setProgress(data?.matchHeader?.state)
      setScoreFetechedDate(new Date())
      setIsFetchingScoreFetechedDate(false)
    } catch (err) {
      console.log(`${err.message}`)
    }
  }

  const refreshFSData = async (fsData) => {
    if (!topicIDFb) {
      return
    }
    let localPollDetails;
    if (fsData && fsData.docs.length >= 1) {
      localPollDetails = formatPollDetails(fsData.docs[0],confirmPartnerName)
    }
    else{
      localPollDetails = await fetchFireStoreData(topicIDFb,confirmPartnerName)
    }

    if(localPollDetails != pollDetails){
      setPollDetails(localPollDetails)
    }
  }

  const refreshMatchIds = () => {
    if(!availableMatches?.matches){
      return
    }
    const [localCurrentMatchId, localNextMatchId] = getCurrentAndNextMatchIds(availableMatches.matches)
    if(localCurrentMatchId!=currentMatchId){
      setCurrentMatchId(localCurrentMatchId)
    }
    if(localNextMatchId!=nextMatchId){
      setNextMatchId(localNextMatchId)
    }
  }

  useEffect(()=>{
    refreshMatchIds()
  },[availableMatches])

  useEffect(()=>{
    refreshScoreAndProgress()
  }, [currentMatchId, nextMatchId])

  /*
  useEffect(()=>{
    refreshFSData()
   },[topicIDFb])
 */
  
  useEffect(() => {
    const [currentMatchData, nextMatchData] = getCurrentAndNextMatchData()
    switch (progress) {
      case 'Complete':
        setTopicIDFb(nextMatchData?.fb_topic_id);
        break;
      default:
        setTopicIDFb(currentMatchData?.fb_topic_id);
        break;
    }
 },[progress]);

 useEffect(() => {
  const interval = setInterval(() => {
    setTime(new Date());
  }, 1000);

  return () => clearInterval(interval);
}, []);

  useEffect(()=>{
    if(moment(time) - moment(scoreFetechedDate) > 10000){
      refreshScoreAndProgress()
    }
  },[time])

  useEffect(()=>{
    if (!topicIDFb) {
      return
    }
    const topic_ref = doc(collection(db, "topics"), topicIDFb);
    const poll_state_open = doc(collection(db, "poll-states"), "OPEN");
    const q = query(collection(db, "polls"),
    where("topic", "==", topic_ref), 
    where("state", "==", poll_state_open),
    where("type", "==", "question"), // this line refers, only fetch (type = "question") 
    orderBy("created_at", "desc") // sorting
  );
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    refreshFSData(querySnapshot)
  });
  return unsubscribe
  }, [topicIDFb])
 

 const [currentMatchData, nextMatchData] = getCurrentAndNextMatchData()
 return (
    <div className="App">
     <div className={rotate ? 'content_rotate' : null}>
      <BackgroundImage rotate={rotate}/>
      
      <div className={centerContent}>
    
       { !score && 
         <PreShowSite 
          currentMatchData={currentMatchData} // this components only show in loading gaps
           viewport={viewport}
           preview={preview}
         />
       }
    
        {currentMatchId &&
          <>
            {score ? <ScoreBoard
              score={score}
              viewport={viewport}
              fontSize={fontSize}
              nextMatchData={nextMatchData}
              preview={preview}
              topicIDFb={topicIDFb}
              pollDetails={pollDetails}
            />
              : null}

            <Batsmen
              score={score}
              viewport={viewport}
              fontSize={fontSize}
            />

          </>
        }
        
         <PoweredBy
          viewport={viewport}
          score={score}
          preview={preview}
          extraLargeViewport={extraLargeViewport}
        />
      
      </div>
      </div>
    </div>
  );
}

export default App;