import React from 'react'
import { Header, Layout, Col, Row, Typography, Image, Flex, Button, QRCode, Card} from 'antd'
import moment from 'moment'

  const PreShowSite = ({currentMatchData ,viewport , preview}) => {
    
   if (currentMatchData == undefined) {
      return
   }
    const { Title, Text } = Typography    
    const matchName = currentMatchData?.description
    const matchStartTime = currentMatchData?.date
    const matchTeam1 = currentMatchData?.home_team_short_name
    const matchTeam2 = currentMatchData?.away_team_short_name
    const ImageWidth = window.innerHeight >= 768 ? 80 : 50; // it refers the window innerheight for change fontsize when it goes to big screen
    const vsHeight = window.innerHeight >= 768 ? 150 : 100  // if refers the vs logo height 
    const nextMatchStartTimeChange = moment(matchStartTime).format('MMMM Do YYYY, h:mm a')  // convert to normal date in moment

  return (
    <div>
 <Row justify={'space-around'} style={{ marginBottom: '10px', marginTop: '20px' }}>
                    <Col xs={viewport.xs} sm={viewport.sm} lg={viewport.lg}>
                        <Row justify={'space-around'} className="logo_board">
                            <Col>
                                {/* this code display,next macth number */}
                                <Row justify={'center'}><Text style={{ textTransform: 'uppercase', color: 'white', textAlign: 'center' }} className='logo_board_font_style_media' ><b>{`${matchName}`}</b></Text></Row>
                                <Row justify={'space-around'}><Text style={{ textAlign: 'center', color: 'lightgreen', padding: '10px' }} className='logo_board_font_style_media'>Match starts at {nextMatchStartTimeChange}</Text></Row>

                                <Row justify={'space-around'}>
                                    {/* this code for showing team logo */}
                                    <Col>
                                        <Row className='logo_board_first_components' >
                                            <Image src={`https://impact11.in/app-assets/teams/${matchTeam1}.png`} preview={preview} width={ImageWidth} style={{ backgroundColor: 'white', borderRadius: '100px' }} />
                                            <Row justify={'center'}><Text strong style={{ color: 'white', marginTop: '5px' }} className='logo_board_font_style_media'> {matchTeam1} </Text></Row>
                                        </Row>
                                    </Col>
                                    {/* this code for VS image */}
                                    <Col>
                                        <div className='logo_board_secound_components'>
                                            <Row justify={'center'}> <Image src='/images/ic_vs1.png' preview={preview} width={vsHeight} /> </Row>
                                        </div>
                                    </Col>
                                    {/* this code for showing team logo */}
                                    <Col>
                                        <Row className='logo_board_third_components'>
                                            <Image src={`https://impact11.in/app-assets/teams/${matchTeam2}.png`} preview={preview} width={ImageWidth} style={{ backgroundColor: 'white', borderRadius: '100px' }} />
                                            <Row justify={'center'}><Text strong style={{ color: 'white', marginTop: '5px' }} className='logo_board_font_style_media'> {matchTeam2} </Text></Row>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
    </div>
  )
}

export default PreShowSite