// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const FIREBASE_APIKEY = process.env.REACT_APP_FIREBASE_APIKEY
const FIREBASE_AUTHDOMAIN = process.env.REACT_APP_FIREBASE_AUTHDOMAIN
const FIREBASE_DATABASEURL = process.env.REACT_APP_FIREBASE_DATABASEURL
const FIREBASE_PROJECTID = process.env.REACT_APP_FIREBASE_PROJECTID
const FIREBASE_STORAGEBUCKET = process.env.REACT_APP_FIREBASE_STORAGEBUCKET
const FIREBASE_MESSAGINGSENDERID = process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID
const FIREBASE_APPID = process.env.REACT_APP_FIREBASE_APPID
const FIREBASE_MEASUREMENTID = process.env.REACT_APP_FIREBASE_MEASUREMENTID

const firebaseConfig = {
  apiKey: FIREBASE_APIKEY,
  authDomain:FIREBASE_AUTHDOMAIN,
  databaseURL:FIREBASE_DATABASEURL,
  projectId: FIREBASE_PROJECTID,
  storageBucket: FIREBASE_STORAGEBUCKET,
  messagingSenderId: FIREBASE_MESSAGINGSENDERID,
  appId: FIREBASE_APPID,
  measurementId: FIREBASE_MEASUREMENTID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


const db = getFirestore(app)
export { db, app }