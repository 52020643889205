import React, { useEffect, useState } from 'react'
import { Header, Layout, Col, Row, Typography, Image, Flex, Button, QRCode, Card  } from 'antd'
import moment, { duration } from 'moment'
import { logEvent } from 'firebase/analytics';
import { useCbFlags } from 'configbee-react'



const getRemainingTime = (pollDetails) => {
    const now = moment(); // Current time using Moment.js
    if (!pollDetails?.deadlineTime) {
        return 'Deadline passed!'
    }
    const countdownDate = moment(pollDetails?.deadlineTime); // Target date using Moment.js
    const duration = countdownDate.diff(now); // Calculate the duration
    if (duration <= 0) {
        return 'Deadline passed!'
    }

    // Calculate days, hours, minutes, and seconds
    const days = Math.floor(duration / (1000 * 60 * 60 * 24));
    const hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((duration % (1000 * 60)) / 1000);

    return `${days ? days + 'd: ' : ''}${hours ? hours + 'h: ': ''} ${minutes ? minutes + 'm: ' : ''} ${seconds}s`;
}
 
//this fucntion get the next match data
const nextMatchDatas = (matchData)=>{
    return {
        nextMatchTeam1 : matchData?.home_team_short_name ,
        nextMatchTeam2 : matchData?.away_team_short_name , 
        nextmatchDescription : matchData?.description ,
        nextMatchStartTime : moment(matchData?.date).format('MMMM Do YYYY, h:mm a')
    }
}

const ScoreBoard = ({ score, viewport, fontSize, nextMatchData, preview, pollDetails, topicIDFb}) => {
    const [time, setTime] = useState(new Date());

    const { enableLivePolls } = useCbFlags() // configbee useflag package for showing poll when flad was true

    // this codes refers the style
    const { Title, Text } = Typography // this is antd design 
    const newStyleChange = { ...fontSize }
    const overColor = { color: 'skyblue' }
    const ImageWidth = window.innerHeight >= 768 ? 80 : 50; // it refers the window innerheight for change fontsize when it goes to big screen
    const vsHeight = window.innerHeight >= 768 ? 150 : 100  // if refers the vs logo height 

    //team Detail 
    const team1 = score.matchHeader.team1.shortName
    const team2 = score.matchHeader.team2.shortName

    //match score  team1`
    const teamScore = score.miniscore?.batTeam?.teamScore
    const wickets = score.miniscore?.batTeam?.teamWkts
    const overs = score.miniscore?.overs
    const runrate = score.miniscore?.currentRunRate
    const status = score.matchHeader?.status
    const scoreStatus = score.miniscore?.status
    const progress = score.matchHeader.state
    // const progress = 'Complete'
    const notStart = score.matchHeader.status

    // toss winnnig team 
    const tossWinnerName = score.matchHeader?.tossResults?.tossWinnerName
    const decision = score.matchHeader?.tossResults?.decision

    // year and match status
    const year = score.matchHeader.year
    const mathcDescription = score.matchHeader.matchDescription
    const seriesName = score.matchHeader.seriesName

    //winer team
    const winnerteam = score.matchHeader.result.winningTeam

    var isMatchStarted = false
    const epochTime = score.matchHeader.matchStartTimestamp // this code get the starting time of match 
    const matchStartTime = moment(epochTime).format('MMMM Do YYYY, h:mm a')
    if (moment() >= epochTime) { // this is current time for finding match start or not
        isMatchStarted = true
    }

    // this code refers to matchcomplete or not ,'if match was completed ,it change the matchCompleted value to true'.
    var matchCompleted = false
    if (progress == "Complete" || progress == "Abandon") {
        matchCompleted = true
    } else {
        matchCompleted = false
    }

    function battingTeam() {
        const battingIndicator = score.miniscore?.matchScoreDetails?.inningsScoreList[0]?.batTeamName
        return battingIndicator
    }

    // this function refers the over convertion , (5.6 => 6)
    function customRound(num) {
        var decimalPart = num - Math.floor(num);
        if (decimalPart > 0.5) {
            return Math.ceil(num);
        } else {
            return num;
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
          setTime(new Date());
        }, 1000);
    
        return () => clearInterval(interval);
    }, []);

    //pollQuestionOption fetch from firbase console
    let option1,option2
    if(pollDetails?.pollQuestionOption[1]){
        option1 = pollDetails.pollQuestionOption[0]?.value
        option2 = pollDetails.pollQuestionOption[1]?.value
    }

    //calling utility function
    const remainingTime = getRemainingTime(pollDetails)
    const getNextMatchData = nextMatchDatas(nextMatchData) 

    //check if next match is available or not
    let isTotalMatchComplete;
        if (getNextMatchData.nextMatchTeam1 == undefined) {
            isTotalMatchComplete = false
        }else{
            isTotalMatchComplete = true
        }
    return (
        <div className='information'>
            {/* this below code are only show when matchcompeted */}
            {matchCompleted && isTotalMatchComplete &&
                <Row justify={'space-around'} style={{ marginBottom: '10px', marginTop: '20px' }}>
                    <Col xs={viewport.xs} sm={viewport.sm} lg={viewport.lg}>
                        <Row justify={'space-around'} className="logo_board">
                            <Col>
                                {/* this code display,next macth number */}
                                <Row justify={'center'}><Text style={{ textTransform: 'uppercase', color: 'white', textAlign: 'center' }} className='logo_board_font_style_media' ><b>{`${getNextMatchData.nextmatchDescription}`}</b></Text></Row>
                                <Row justify={'center'}><Text style={{ textTransform: 'uppercase', color: 'orange ', textAlign: 'center', padding:'10px' }} className='logo_board_font_style_media' > upcoming</Text></Row>
                                <Row justify={'space-around'}><Text style={{ textAlign: 'center', color: 'lightgreen' ,paddingBottom:'10px'}} className='logo_board_font_style_media' >Match starts at {getNextMatchData.nextMatchStartTime}</Text></Row>

                                <Row justify={'space-around'}>
                                    {/* this code for showing team logo */}
                                    <Col>
                                        <Row className='logo_board_first_components' >
                                            <Image src={`https://impact11.in/app-assets/teams1/${getNextMatchData.nextMatchTeam1}.png`} preview={preview} width={ImageWidth} style={{ backgroundColor: 'white', borderRadius: '100px' }} />
                                            <Row justify={'center'}><Text strong style={{ color: 'white', marginTop: '5px' }} className='logo_board_font_style_media'> {getNextMatchData.nextMatchTeam1} </Text></Row>
                                        </Row>
                                    </Col>
                                    {/* this code for VS image */}
                                    <Col>
                                        <div className='logo_board_secound_components'>
                                            <Row justify={'center'}> <Image src='/images/ic_vs1.png' preview={preview} width={vsHeight} /> </Row>
                                        </div>
                                    </Col>
                                    {/* this code for showing team logo */}
                                    <Col>
                                        <Row className='logo_board_third_components'>
                                            <Image src={`https://impact11.in/app-assets/teams1/${getNextMatchData.nextMatchTeam2}.png`} preview={preview} width={ImageWidth} style={{ backgroundColor: 'white', borderRadius: '100px' }} />
                                            <Row justify={'center'}><Text strong style={{ color: 'white', marginTop: '5px' }} className='logo_board_font_style_media'> {getNextMatchData.nextMatchTeam2} </Text></Row>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            }
        {/* this code was showing questions */}
        {matchCompleted && remainingTime !== 'Deadline passed!' && <>
          {enableLivePolls && <>
            {topicIDFb ? //if topicIDFb will be null or undifined ,this section will not be display 
            <Row className="logo_board_2_poll">
                {pollDetails?.pollQuestionTitle ?
                    <Col xs={viewport.xs} sm={viewport.sm} lg={viewport.lg} className="logo_board">
                        <Row className='predict_now' justify={'space-around'} style={{textAlign:'center'}}> Scan to predict & win rewards!</Row>
                        <Row>
                            <Col>
                               <Row><Title level={2} style={{color:'white',textAlign:'center'}}>{pollDetails?.pollQuestionTitle}</Title></Row>
                                <Row  className='poll_and_qrCode'>
                                    {option1 && option2 && <Col>
                                        <Row justify={'space-around'}>
                                            <div className="button-wrapper">
                                                <a className="custom-button"> <span> {option1} </span></a>
                                            </div>
                                            <div className="button-wrapper_two">
                                                <a className="custom-button-two"> <span> {option2} </span></a>
                                            </div>
                                        </Row>
                                    </Col>
                                }
                                </Row>
                                <Row>
                                    <Col>
                                        {/* <Row>
                                            <Title level={3} style={{color:'white',textAlign:'center'}}> Scan the QR code <br /> win exclusive rewards! </Title>
                                        </Row> */}
                                        <Row>
                                            <QRCode value={pollDetails?.qrUrl} color='black' bgColor='white' size={150} className='QRCode' />
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='date'>
                                        {remainingTime}
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </Col> : null}
            </Row> : null } 
            </>} 
            </>}

            {/* this code will show always */}
            <Row justify={'space-around'} style={{marginTop:'10px'}}>
                <Col xs={viewport.xs} sm={viewport.sm} lg={viewport.lg}>
                    <Row justify={'space-around'} className="logo_board" >
                        <Col>
                            <Row justify={'center'}><Text style={{ textTransform: 'uppercase', color: 'white', textAlign: 'center' }} className='logo_board_font_style_media' ><b>{`${seriesName} `}- {`${mathcDescription}`}</b></Text></Row>
                            <Row justify={'center'}><Text style={{ textAlign: 'center', color: 'lightgreen', textTransform: 'uppercase', margin: '10px' }} className='logo_board_font_style_media' >{progress == 'Toss' ? `${progress} : ${tossWinnerName} (${decision})` : progress}</Text></Row>
                            <Row justify={'space-around'}>
                                <Col>
                                    {/* this code for set team image and name  */}
                                    <Row className='logo_board_first_components' >
                                        <Image src={`https://impact11.in/app-assets/teams1/${team1}.png`} preview={preview} width={ImageWidth} style={{ backgroundColor: 'white', borderRadius: '100px' }} className='vadivel' />
                                        <Row justify={'center'}><Text strong style={{ color: 'white', marginTop: '5px' }} className='logo_board_font_style_media'> {team1} </Text></Row>
                                    </Row>
                                </Col>
                                {/* this code for cented VS image */}
                                <Col>
                                    <div className='logo_board_secound_components'>
                                        <Row justify={'center'}> <Image src='/images/ic_vs1.png' preview={preview} width={vsHeight} /> </Row>
                                    </div>
                                </Col>
                                {/* this code for set team image and name  */}
                                <Col>
                                    <Row className='logo_board_third_components'>
                                        <Image src={`https://impact11.in/app-assets/teams1/${team2}.png`} preview={preview} width={ImageWidth} style={{ backgroundColor: 'white', borderRadius: '100px' }} />
                                        <Row justify={'center'}><Text strong style={{ color: 'white', marginTop: '5px' }} className='logo_board_font_style_media'> {team2} </Text></Row>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>

        {!matchCompleted &&  remainingTime !== 'Deadline passed!' && <>
          {enableLivePolls && <>
            {topicIDFb ? //if topicIDFb will be null or undifined ,this section will not be display 
            <Row className="logo_board_2_poll" style={{marginTop:'10px'}}>
                {pollDetails?.pollQuestionTitle ?
                    <Col xs={viewport.xs} sm={viewport.sm} lg={viewport.lg} className="logo_board">
                        <Row className='predict_now' justify={'space-around'}> Scan to predict & win rewards!</Row>
                        <Row>
                            <Col>
                               <Row><Title level={2} style={{color:'white',textAlign:'center'}}>{pollDetails?.pollQuestionTitle}</Title></Row>
                                <Row  className='poll_and_qrCode'>
                                    <Col>
                                        <Row justify={'space-around'}>
                                            <div className="button-wrapper">
                                                <a className="custom-button"> <span> {option1} </span></a>
                                            </div>
                                            <div className="button-wrapper_two">
                                                <a className="custom-button-two"> <span> {option2} </span></a>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {/* <Row>
                                            <Title level={3} style={{color:'white',textAlign:'center'}}> Scan the QR code <br /> win exclusive rewards! </Title>
                                        </Row> */}
                                        <Row>
                                            <QRCode value={pollDetails?.qrUrl} color='black' bgColor='white' size={150} className='QRCode' />
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='date'>
                                        {remainingTime}
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </Col> : null}
            </Row> : null } 
            </>} 
            </>}

            {/* this is showing score and current run rate box*/}
            <Row className="logo_board_2" justify={'space-around'}>
                <Col xs={viewport.xs} sm={viewport.sm} lg={viewport.lg}>
                    {/* this code only display when the match stated ,it refers scores*/}
                    {isMatchStarted ? <Row justify={'space-around'} className="logo_board" >
                        <Col>
                           {progress !== 'Abandon' && <Row justify={'space-around'} >
                                <Text style={newStyleChange} className='logo_board_font_style_media' > <strong>{battingTeam()} <b> {teamScore}/{wickets} </b> <span style={overColor}> ({customRound(overs)}) </span>  CRR: {runrate} </strong> </Text>
                            </Row>
                          }
                            <Row justify={'space-around'}>
                                <Text style={{ color: 'orange', textAlign: 'center' }} className='logo_board_font_style_media'> {progress === 'Abandon' ? status : scoreStatus} </Text>
                            </Row>
                        </Col>
                    </Row> :
                        //if match not start ,this code display when match starts
                        <Row justify={'space-around'} className="logo_board" style={{ padding: '10px' }}>
                            <Col>
                                <Row justify={'space-around'} >
                                    <Text style={{ color: 'white' }} className='logo_board_font_style_media'> <strong> Match starts at <b >{matchStartTime}</b> </strong></Text>
                                </Row>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </div>
    )
}

export default ScoreBoard