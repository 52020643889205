import React, { useEffect, useState  } from 'react'
import { Row, Image, Col } from 'antd'

const PoweredBy = ({ viewport, score, preview ,extraLargeViewport}) => {
    // this code refers if minscore is undifined it change the isLoading to false
    var isLoading = false
    // if (score.miniscore === <LoadingOutlined >) { isLoading = true }

    const impactLogoHeight = window.innerHeight >= 768 ? 45 : 40  // fixing logo height based on screen height
    const LogoHeightFutureWall = window.innerHeight >= 768 ? 55 : 50  // fixing logo height based on screen height
    const imagesRowDiv = window.innerHeight >= 900 ? 'bigScreen' : 'smallScreen' // this is for imagesDiv

    // this code for change the poweredby logos based on url
    const [logos,setLogos] = useState('')
    const urlParams = new URLSearchParams(window.location.search)
    const key = urlParams.get('partner')
    useEffect(()=>{
    switch (key) {
        case undefined:
        case null:
          setLogos(null);
          break;
        case 'adstandee':
          setLogos('/images/adstandee_logo.png');
          break;
        case 'tfw':
          setLogos('/images/The_future_wall_logo.png');
          break;
        default:
        break;
      }
    },[undefined])
      

    return (
        <div>
            <Row className='poweredby_row'>
                <Col xs={18} sm={16} lg={extraLargeViewport} xl={14}>
                    <div className='logo_board_font_style_media'> {/* this line only for finxing font size */}
                       <div className="poweredby_secound_Row"> {/* this code for background blur for getting code */}
                                <Row className='poweredBy'>
                                    Powered By
                                </Row>
                                <Row className='poweredBy'>
                                    <div className={imagesRowDiv}>
                                     <Col className='poweredBy_impactLogo'>    
                                       <Image src='/images/impact11.png' height={impactLogoHeight} preview={preview} className='impactLogo_ImageTag'/> 
                                     </Col>
                                      {logos ? <div className='ambrased'>&</div> : null}
                                    {logos ?  <Image src={logos}  height={LogoHeightFutureWall} preview={preview} className={key === 'adstandee' ? 'adstandee_logo' : null} /> :null}
                                   </div>    
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default PoweredBy

 